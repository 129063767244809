import React from 'react';
import { Hidden } from '@material-ui/core';

import GridLayout from 'src/components/draggableGrid';
import SEO from 'src/components/seo';

// import FilterBy from 'src/features/groupPerformance/filterBy';
// import ConnectedCalls from 'src/features/groupPerformance/connectedCalls';
// import CallDuration from 'src/features/groupPerformance/callDuration';
// import MasterDatePicker from 'src/features/groupPerformance/masterDatePicker';
import { GroupCallDuration, GroupCallResults, GroupCallAttempt, GroupDataFilters, RepPerformsnceReportFiltersPanel } from 'src/features/groupPerformance';

export default function Onboarding() {
  return (
    <>
      <SEO title="Group performance" />

      <Hidden smDown>
        <GroupDataFilters />
      </Hidden>

      <RepPerformsnceReportFiltersPanel />

      <GridLayout>
        <GroupCallAttempt />

        {/* <GroupCallResults /> */}

        <GroupCallDuration />
      </GridLayout>

      {/* <Hidden smDown>
        <FilterBy />
      </Hidden>
      <MasterDatePicker />
      <GridLayout>
        <ConnectedCalls />
        <CallDuration />
      </GridLayout> */}
    </>
  );
}
